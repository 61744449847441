import { IconArrowChevronDownLrg, TextBody } from "@sourceful/design-system-v3";

interface FAQItemTriggerProps {
  text: string;
  icon?: React.ReactNode;
}

export default function FAQItemTrigger({ text, icon }: Readonly<FAQItemTriggerProps>) {
  return (
    <>
      <span className="flex flex-row items-start gap-16 mr-8">
        {icon && (
          <span className="flex shrink-0 items-center justify-center size-24 rounded-sm md:mt-2 xl:mt-4 bg-ui-darkest text-ui-lightest">
            {icon}
          </span>
        )}
        <TextBody size="medium" weight={"medium"} className="text-start">
          {text}
        </TextBody>
      </span>
      <IconArrowChevronDownLrg className="ml-auto size-24 transition-transform duration-200" />
    </>
  );
}
