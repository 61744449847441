import { cn } from "@sourceful/design-system-v3";

interface CallToActionSectionProps {
  children?: React.ReactNode;
  className?: string;
}

export default function CallToActionSection({
  children,
  className,
}: Readonly<CallToActionSectionProps>) {
  return (
    <section className={cn("col-span-full flex flex-col gap-24 lg:gap-32", className)}>
      <div className="flex flex-col gap-24 items-center bg-gradient-br-violet-blue p-24 rounded-md dark lg:flex-row lg:justify-between">
        {children}
      </div>
    </section>
  );
}
