"use client";

import { useDatadogPageViews } from "@app/hooks/useDatadogPageViews";
import { isTrackingDisabled } from "@app/hooks/useThirdParty";
import { GoogleTagManager } from "@next/third-parties/google";
import dynamic from "next/dynamic";
import {
  FunctionComponent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDatadog } from "../../../../lib/hooks/useDatadog";
import env from "../../../../lib/utils/env";
import { i18n } from "../../../../next-i18next.config";

// Polyfills can go here (or in specific components), should be part of main bundle then
import { IconAlertExclamationFill, Toaster, useToast } from "@sourceful/design-system-v3";
import "@ungap/global-this";
import { FormStep, GenerateForm } from "../../components/generate-dialog/forms/formFactory";
import SlashIdAuthProvider from "../SlashIdAuthProvider/SlashIdAuthProvider";
import { useLocalStorage } from "react-use";
import { useReCaptcha } from "next-recaptcha-v3";
import { useFeatureFlag } from "../../../../lib/flags/flags.client";
import { FlagName } from "@sourceful/shared-utils/flag-utils";
import { useSlashIdAuthentication } from "../SlashIdAuthProvider/useSlashIdAuthentication";
import { useSaveGenerateFormPostLogin } from "../../helpers/useSaveGenerateFormPostLogin";

const API_ORIGIN = env("API_ORIGIN");

const GenerateDialog = dynamic(() => import("../../components/generate-dialog/GenerateDialog"), {
  ssr: false,
});

const GTM_CODE = env("SPRING_GTM_CODE");

export interface PageProviderInjectedProps {
  locale: string;
  setShowGenerateDialog: (show: boolean) => void;
  setGenerateDialogDefaultValues: (values: Partial<GenerateForm>) => void;
}

export interface PageProviderProps {
  children?: React.ReactNode;
}

const PageContext = createContext({} as PageProviderInjectedProps);

const usePageContext = () => useContext(PageContext);

const CorePageProvider: FunctionComponent<PageProviderProps> = ({ children }) => {
  const { userEmail, isLoading: userLoading } = useSlashIdAuthentication();
  const [showGenerateDialog, setShowGenerateDialog] = useState(false);
  const [generateDialogDefaultValues, setGenerateDialogDefaultValues] =
    useState<Partial<GenerateForm>>();

  const [initialStep, setInitialStep] = useState(FormStep.Define);

  useDatadog();
  useDatadogPageViews();

  const debugEnabled = useFeatureFlag(FlagName.SPRING_DEBUG_ENABLED, false);

  const [, setCustomerName] = useLocalStorage("customer_name", "");
  const [, setUserEmail] = useLocalStorage("user_email", "");

  const { executeRecaptcha, error: recaptchaError, loaded: recaptchaLoaded } = useReCaptcha();
  const { toast } = useToast();

  useEffect(() => {
    if (recaptchaError) {
      console.error("RECAPTCHA failed to load");
    }
  }, [recaptchaError]);

  const submitForm = useCallback(
    async (data: GenerateForm) => {
      console.debug("Form submitted", data);

      try {
        setCustomerName(data.customer_name);
        setUserEmail(data.user_email);

        const token = await executeRecaptcha("form_submit");

        data.token = token;
        data.debug = debugEnabled;

        const body = JSON.stringify(data);

        const res = await fetch(`${API_ORIGIN}/packaging-inspiration-api/generate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: body,
        });
        if (!res.ok) {
          const error = await res.json();
          throw error.message || "Failed to submit form"; // not Error(message) because we want to differentiate between API and network errors as fetch only throws for network errors
        }
      } catch (error) {
        console.error("Error submitting form", error);
        let message: string | undefined = undefined;
        if (typeof error === "string") {
          message = error;
          if (error.includes("exceeded threshold"))
            message =
              "Our AI flagged your request for manual review. You can choose to retry your request or you can wait for us to review it manually.";
        }

        toast({
          title: "Error submitting form",
          description: `${
            message ? message + "\n" : ""
          } Please try again, if the problem persists please contact us.`,
          variant: "error",
          duration: 10e3,
          figure: <IconAlertExclamationFill className="size-32" />,
        });
      }
    },
    [executeRecaptcha, setCustomerName, setUserEmail, toast, debugEnabled]
  );

  // for SSO users their form is only submitted post-login. We persist the form in local storage
  // and then restore it post-login so that the final Thanks step can be displayed

  const triggerPostLoginConfirmation = useCallback((restoredForm: GenerateForm) => {
    setGenerateDialogDefaultValues(restoredForm);
    setInitialStep(FormStep.Thanks);
    setShowGenerateDialog(true);
  }, []);

  useSaveGenerateFormPostLogin({
    userEmail: userEmail ?? null,
    userLoading,
    recaptchaLoaded,
    submitForm,
    triggerConfirmation: triggerPostLoginConfirmation,
  });

  return (
    <PageContext.Provider
      value={{
        locale: i18n.defaultLocale,
        setShowGenerateDialog,
        setGenerateDialogDefaultValues,
      }}
    >
      {!isTrackingDisabled() && GTM_CODE && <GoogleTagManager gtmId={GTM_CODE} />}
      {children}

      {showGenerateDialog && (
        <GenerateDialog
          submitForm={submitForm}
          recaptchaError={recaptchaError}
          recaptchaLoaded={recaptchaLoaded}
          defaultValues={generateDialogDefaultValues}
          onClose={() => {
            setShowGenerateDialog(false);
            setGenerateDialogDefaultValues(undefined);
            setInitialStep(FormStep.Define);
          }}
          initialStep={initialStep}
        />
      )}

      {/* NOTE: Quick fix - we have issues with nesting now z-indexes come into play.
      So the close button popover doesn't inherit this because its rendered in the body */}
      <div className="relative z-50">
        <Toaster swipeDirection="right" />
      </div>
    </PageContext.Provider>
  );
};

const PageProvider: FunctionComponent<PageProviderProps> = ({ children }) => {
  return (
    <SlashIdAuthProvider>
      <CorePageProvider>{children}</CorePageProvider>
    </SlashIdAuthProvider>
  );
};

export { PageContext, PageProvider, usePageContext };
