"use client";

import { Grid, TextBody, TextLabel, screens } from "@sourceful/design-system-v3";
import dynamic from "next/dynamic";
import useMedia from "react-use/lib/useMedia";
import { SanityFooterSection } from "../../../groq/siteFooterConfig";
import StandardLink from "../sanity/StandardLink";

// Lazy load, pulled into a separate component to keep imports clean
const FooterAccordion = dynamic(() => import("./FooterAccordion"));

interface FooterSectionsProps {
  data: SanityFooterSection[] | undefined;
}

export default function FooterSections({ data }: Readonly<FooterSectionsProps>) {
  const isMdOrAbove = useMedia(`(min-width: ${screens.md})`, true);

  return (
    <Grid
      columns="6"
      className="gap-0 divide-y divide-ui-grey-500 border-t border-b border-ui-grey-500 md:divide-y-0 md:border-none"
    >
      {isMdOrAbove &&
        data?.map(footerSection => {
          return (
            <div
              className="col-span-6 md:col-span-2 flex flex-col gap-24 py-24"
              key={footerSection.title}
            >
              <TextBody size="kicker" asChild>
                <h3 className="bg-none text-ui-lightest">{footerSection.title}</h3>
              </TextBody>

              <TextLabel size="large" asChild>
                <ul className="space-y-12">
                  {footerSection.sectionLinks.map(sectionLink => (
                    <li key={`${sectionLink?._key}`}>
                      <StandardLink link={sectionLink} variant="footer">
                        {sectionLink.name}
                      </StandardLink>
                    </li>
                  ))}
                </ul>
              </TextLabel>
            </div>
          );
        })}

      {!isMdOrAbove &&
        data?.map(footerSection => (
          <FooterAccordion key={footerSection.title} data={footerSection} />
        ))}
    </Grid>
  );
}
