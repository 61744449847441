import { useSlashID } from "@slashid/react";

export const useSlashIdAuthentication = () => {
  const { user, logIn, logOut, isAuthenticated, isLoading } = useSlashID();

  return {
    user: user?.tokenClaims,
    userEmail: user?.authentications?.find(a => a?.handle?.type === "email_address")?.handle.value,
    logOut,
    logIn,
    isAuthenticated,
    isLoading,
  };
};
