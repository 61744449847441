import { TextBody } from "@sourceful/design-system-v3";

interface FAQItemContentProps {
  children: React.ReactNode;
}

export default function FAQItemContent({ children }: Readonly<FAQItemContentProps>) {
  return (
    <TextBody size="small" className="p-16" asChild>
      <div className="flex flex-col space-y-16">{children}</div>
    </TextBody>
  );
}
