import { GenerateForm } from "../components/generate-dialog/forms/formFactory";
export const GENERATE_FORM_LOCAL_STORAGE_KEY = "spring_generate_form_data";

export const persistGenerateForm = (form: GenerateForm) => {
  localStorage.setItem(GENERATE_FORM_LOCAL_STORAGE_KEY, JSON.stringify(form));
};

export const clearPersistedGenerateForm = () => {
  localStorage.removeItem(GENERATE_FORM_LOCAL_STORAGE_KEY);
};

export const getPersistedGenerateForm = (): GenerateForm | null => {
  const raw = localStorage.getItem(GENERATE_FORM_LOCAL_STORAGE_KEY);
  try {
    if (!raw) return null;
    const parsed = JSON.parse(raw);
    return parsed as GenerateForm;
  } catch (error) {
    return null;
  }
};
