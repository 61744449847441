"use-client";

import { SlashIDProvider, ConfigurationProvider } from "@slashid/react";
import "@slashid/react/style.css";
import { Factor, SlashIDEnvironment } from "@slashid/slashid";
import { ReactNode } from "react";
import env from "../../../../lib/utils/env";

export interface Organisation {
  display_name: string;
  id: string;
  metadata: any;
  name: string;
  org_id: string;
  uuid: string;
  roles: string[];
}

export interface SourcefulUser {
  phone_number: string | null;
  phone_verified: boolean | null;
  username: string;
  app_metadata: any;
  user_id: string;
  uuid: string;
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  updated_at: string;
  email: string;
  organisations: Organisation[];
  org: Organisation;
  created_at: string;
  user_metadata?: {
    phone_number?: string;
    [index: string]: any;
  };
}

// For now only DSSMITH requires SSO and auth on spring - in order to support dynamic selection of orgs we would have to implement our own logic with the core sdk
// we should look into this in the future
const oid = env("SLASHID_ORGANIZATION_ID_DS_SMITH") || "";
const environment = (env("SLASHID_ENVIRONMENT") as SlashIDEnvironment) || "sandbox";
console.debug(`oid: ${oid}`);
interface SlashIdAuthProviderProps {
  children: ReactNode;
}

const SlashIdAuthProvider = ({ children }: SlashIdAuthProviderProps) => {
  const factors: Factor[] = [{ method: "otp_via_email" }];

  return (
    <SlashIDProvider oid={oid} environment={environment} tokenStorage="localStorage">
      <ConfigurationProvider factors={factors} text={{ "initial.title": "Welcome to Sourceful" }}>
        {children}
      </ConfigurationProvider>
    </SlashIDProvider>
  );
};

export default SlashIdAuthProvider;
