import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Grid,
  IconAlertQuestionOutline,
  IconProductsBox,
  IconShoppingBasket,
  IconToolsAi,
  IconToolsPalettePicker,
  TextBody,
  TextHeadline,
  cn,
} from "@sourceful/design-system-v3";
import FAQItemContent from "./FAQItemContent";
import FAQItemTrigger from "./FAQItemTrigger";

interface FAQSectionProps {
  className?: string;
}

export default function FAQSection({ className }: Readonly<FAQSectionProps>) {
  return (
    <section className={cn("pt-40 flex flex-col gap-40 lg:gap-72", className)}>
      <div className="flex flex-col gap-24 max-w-2xl">
        <TextBody size="kicker" className="w-fit" asChild>
          <p>Most frequently asked questions</p>
        </TextBody>
        <TextHeadline size="large" asChild>
          <h2>Do you have any questions?</h2>
        </TextHeadline>
      </div>
      <div className="">
        <Grid margin={"no"} className="grid-cols-1 md:grid-cols-2">
          <div className="flex flex-col gap-16 md:gap-24">
            <Accordion type="single" collapsible defaultValue="" className="border-none">
              <AccordionItem value="faq-1" className="border-none bg-ui-lightest rounded-sm">
                <AccordionTrigger wrapperElement={"h3"} className="p-16">
                  <FAQItemTrigger
                    text="Can I create packaging designs for free?"
                    icon={<IconToolsPalettePicker className="size-20 transform-none" />}
                  />
                </AccordionTrigger>
                <AccordionContent>
                  <FAQItemContent>
                    <TextBody size="small" weight={"medium"} asChild>
                      <h4>Design freedom, without the price tag</h4>
                    </TextBody>
                    <p>
                      Unleash your creativity without opening your wallet. With Spring, you can
                      explore endless packaging possibilities and generate unique designs at zero
                      cost. But we don't just stop at free design - we're here to bring your vision
                      to life. When you're ready to make your digital dreams a tangible reality, our
                      team is poised to turn your designs into high-quality, sustainable packaging
                      that'll make your brand stand out.
                    </p>
                  </FAQItemContent>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <Accordion type="single" collapsible defaultValue="" className="border-none">
              <AccordionItem value="faq-1" className="border-none bg-ui-lightest rounded-sm">
                <AccordionTrigger wrapperElement={"h3"} className="p-16">
                  <FAQItemTrigger
                    text="How can I purchase the packaging I've designed?"
                    icon={<IconShoppingBasket className="size-20 transform-none" />}
                  />
                </AccordionTrigger>
                <AccordionContent>
                  <FAQItemContent>
                    <TextBody size="small" weight={"medium"} asChild>
                      <h4>From Design to Doorstep: Your Packaging Journey</h4>
                    </TextBody>
                    <p>
                      Ready to bring your packaging vision to life? It's easier than you think. Once
                      you've perfected your design using our AI-powered platform, simply get in
                      touch and we'll spring into action. But we don't just process your order - we
                      become your packaging partner. We'll guide you through customisation options,
                      help you choose sustainable materials, and even optimise for cost-efficiency.
                      Before you know it, your brand-new packaging will be making its grand entrance
                      at your doorstep, ready to wow your customers.
                    </p>
                  </FAQItemContent>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <Accordion type="single" collapsible defaultValue="" className="border-none">
              <AccordionItem value="faq-1" className="border-none bg-ui-lightest rounded-sm">
                <AccordionTrigger wrapperElement={"h3"} className="p-16">
                  <FAQItemTrigger
                    text="Can you use AI to design specific products?"
                    icon={<IconProductsBox className="size-20 transform-none" />}
                  />
                </AccordionTrigger>
                <AccordionContent>
                  <FAQItemContent>
                    <TextBody size="small" weight={"medium"} asChild>
                      <h4>Packaging that Pops: Your Products, Perfectly Presented</h4>
                    </TextBody>
                    <p>
                      Imagine your product, nestled in its ideal packaging, ready to wow your
                      customers. That's exactly what our AI-powered tool delivers - and then some.
                      We don't just generate packaging designs; we bring your entire product
                      experience to life. Our cutting-edge AI creates stunningly realistic packaging
                      structures, tailored to your brand. But we don't stop there. We go the extra
                      mile, placing your products in lifelike settings and even showcasing them
                      inside the packaging. It's like having a professional photoshoot at your
                      fingertips, without the hefty price tag or time investment. From sleek boxes
                      to eye-catching bags, our tool crafts packaging that not only protects your
                      products but also tells your brand story. It's more than just packaging design
                      - it's a glimpse into your products future on the shelf and in your customers'
                      hands.
                    </p>
                  </FAQItemContent>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="flex flex-col gap-16 md:gap-24">
            <Accordion type="single" collapsible defaultValue="" className="border-none">
              <AccordionItem value="faq-1" className="border-none bg-ui-lightest rounded-sm">
                <AccordionTrigger wrapperElement={"h3"} className="p-16">
                  <FAQItemTrigger
                    text="How do I create packaging designs with AI?"
                    icon={<IconToolsAi className="size-20 transform-none" />}
                  />
                </AccordionTrigger>
                <AccordionContent>
                  <FAQItemContent>
                    <TextBody size="small" weight={"medium"} asChild>
                      <h4>Design Brilliance at Your Fingertips</h4>
                    </TextBody>
                    <p>
                      Unleash your creativity with AI-powered packaging design - no design degree
                      required. Follow the 3 simple steps inputting your product and your brand
                      aesthetics, then watch as our intelligent platform generates unique concepts
                      in minutes. You'll receive an email within minutes linking to your 9 new AI
                      generated packaging designs for your brand. If you want to tweak your designs,
                      use the remix button to generate new AI designs with the same prompts.
                    </p>
                  </FAQItemContent>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <Accordion type="single" collapsible defaultValue="" className="border-none">
              <AccordionItem value="faq-1" className="border-none bg-ui-lightest rounded-sm">
                <AccordionTrigger wrapperElement={"h3"} className="p-16">
                  <FAQItemTrigger
                    text="What is AI in packaging?"
                    icon={<IconAlertQuestionOutline className="size-20 transform-none" />}
                  />
                </AccordionTrigger>
                <AccordionContent>
                  <FAQItemContent>
                    <TextBody size="small" weight={"medium"} asChild>
                      <h4>Packaging Design, Supercharged</h4>
                    </TextBody>
                    <p>
                      AI packaging design is your creative genius. It's where cutting-edge
                      artificial intelligence meets your wildest packaging dreams, turning ideas
                      into stunning visuals faster than you can say "unbox." Welcome to the future
                      of packaging - where your imagination is the only limit.
                    </p>
                  </FAQItemContent>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </Grid>
      </div>
    </section>
  );
}
