import { useEffect } from "react";
import { GenerateForm } from "../components/generate-dialog/forms/formFactory";
import { clearPersistedGenerateForm, getPersistedGenerateForm } from "./persistGenerateForm";

export interface UseSaveGenerateFormPostLoginArgs {
  userEmail: string | null;
  recaptchaLoaded: boolean;
  submitForm: (form: GenerateForm) => Promise<void>;
  userLoading: boolean;
  triggerConfirmation: (form: GenerateForm) => void;
}

export const useSaveGenerateFormPostLogin = ({
  userEmail,
  recaptchaLoaded,
  submitForm,
  userLoading,
  triggerConfirmation,
}: UseSaveGenerateFormPostLoginArgs) => {
  useEffect(() => {
    const restoredForm = getPersistedGenerateForm();
    if (!restoredForm) return;

    if (!recaptchaLoaded) return;
    if (userLoading) return;

    // if the user is not authenticated clear any dangling persisted forms
    if (!userEmail) return clearPersistedGenerateForm();

    if (restoredForm.user_email !== userEmail) {
      console.debug(
        `User email ${userEmail} does not match user submitted email ${restoredForm.user_email} - clearing persisted form`
      );
      return clearPersistedGenerateForm();
    }

    submitForm(restoredForm);
    triggerConfirmation(restoredForm);
    clearPersistedGenerateForm();
  }, [submitForm, recaptchaLoaded, userLoading, userEmail, triggerConfirmation]);
};
